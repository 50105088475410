html,
body,
#root,
.app,
.content {
  width: 100%;
  /* height: calc(100vh - 64px); */
  height: 100vh;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: clip;
}
/* 
@font-face {
  font-family: "GreycliffCFRegular";
  src: local("GreycliffCFRegular"),
    url("./fonts/GreycliffCF-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "GreycliffCFMedium";
  src: local("GreycliffCFMedium"),
    url("./fonts/GreycliffCF-Medium.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "GreycliffCFBold";
  src: local("GreycliffCFBold"),
    url("./fonts/GreycliffCF-Bold.otf") format("opentype");
  font-weight: normal;
} */

.app {
  display: flex;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form.MuiBox-root .MuiButtonBase-root {
  margin-top: 4%;
  color: white;
  background-color: #cc4105;
}
.MuiInputBase-root .MuiOutlinedInput-root {
  bottom: 50px;
  font-family: "Poppins" !important;
}

.css-118vapk-MuiDataGrid-root .MuiDataGrid-cellContent {
  font-size: 0.8rem !important;
  font-family: "Poppins" !important;
  font-size: 14px !important;
}
.css-118vapk-MuiDataGrid-root .MuiDataGrid-row {
  background-color: white;
}
#email {
  border: 1px solid white;
  width: "88px" !important;
  background-color: #fafafa;
  height: 0px;
}

.css-1eth2a0-MuiFormLabel-root-MuiInputLabel-root.Mui-focused
  #facility-name-select-small-label {
  color: #000 !important;
}

.facilityNameField .MuiDataGrid-cellContent {
  font-family: "Poppins" !important;
  font-size: 14px !important;
}

.pageNumber .MuiPagination-ul .Mui-selected{
  color: #fafafa !important;
}
.editableDiv{
  background-color: #ededed;
}

label,
textarea,
fieldset,
span,
div,
li,
input {
  font-family: "Poppins" !important;
}

.bi-embedded {
  height: 490px;
  width: 100%;
  margin-top: -25px;
  margin-left: -30px;
}

.bi-embedMediumPixel {
  height: 510px;
  width: 100%;
  margin-top: -25px;
  margin-left: -30px;
}

.bi-embedHighPixel {
  height: 680px;
  width: 100%;
  margin-top: -25px;
  margin-left: -30px;
}

.custom-tab-class.Mui-selected {
    color: #dc4f34 !important;
    /* padding:12px 8px !important; */
}
.custom-tab-class {
    /* padding:12px 8px !important; */
}
.tab-container{
  .MuiTabs-flexContainer{
    display:flex;
    flex-wrap:wrap !important;
  }
}

.datepickeroutline div {
  height: 40px !important;
}
