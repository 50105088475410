.rbc-today{
    background-color: inherit;
}
.rbc-time-header-content .rbc-allday-cell .rbc-row:nth-child(1)  {
 margin-top: 28px;
}

.rbc-event-allday .rbc-event-content ~  .rbc-event-allday .rbc-event-content{
    background-color: inherit;
   }
  .rbc-row.rbc-time-header-cell {
    display: none;
} 
.rbc-today {
    background-color: inherit;
} 

 .rbc-date-cell button {
    visibility: hidden;
} 
.rbc-event, .rbc-event.rbc-selected {
    border-left: 5px solid var(--Color-Primary, #DC4F34) !important;
    background: var(--color-primary-4, #F5CAC2);
    color: #000;
}