:root {
  --off-white: #fffffe;
  --background: #fafafa;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}

.circle-wrap {
  /* width: 300px;
  height: 300px;
  border: 10px solid #212121;
  border-radius: 50%;
  border-left-color: transparent;
  transform: rotate(180deg);
  margin-top: 75px;
  position: absolute; */

  transform: rotate(360deg);
  border: 1px solid #212121;
  /* border-bottom-left-radius: 250px; */
  border-right: 0;
  /* border-top-left-radius: 250px; */
  box-sizing: border-box;
  height: 500px;
  width: 500px;
  border-radius: 50%;
  margin-top: 300px;
  position: absolute;
  right: -110px;
  /* width: 250px; */
  /* top: 45%; */
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#radius {
  height: 0px;
  width: 100%;
  border: 1px solid red;
  top: 50%;
  position: absolute;
}

.inner-circle-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(360deg);
  border-radius: 50%;
  background: #292b2d;
  border: 1px solid #212121;
  /* opacity: 90%; */
  height: 470px;
  top: 15px;
  width: 470px;
  /* border: 1px solid gray; */
  /* border-bottom-left-radius: 250px; */
  border-right: 0;
  /* border-top-left-radius: 250px; */
  box-sizing: border-box;
  position: absolute;
  right: 0;
}

.inner-circle-wrap-text {
  /* word-wrap: break-word; */
  color: #fff;
  /* max-width: 130px; */
  position: relative;
  /* top: 30%; */
  margin: auto;
  text-align: right;
}

.icon-background {
  position: absolute;
}

#honeyAssetSvgFour {
  bottom: 175px;
  left: -50px;
}

#honeyAssetSvgThree {
  top: 55px;
  left: 0px;
}

#honeyAssetSvgFive {
  bottom: 55px;
  left: 0px;
}

#honeyAssetSvgSix {
  bottom: -30px;
  left: 101.5px;
}

#honeyAssetSvgOne {
  top: -50px;
  left: 240px;
}

#honeyAssetSvgSeven {
  bottom: -50px;
  left: 240px;
}

#honeyAssetSvgTwo {
  top: -30px;
  left: 101.5px;
}
