#honey-svg-asset > svg {
  fill: #f37021;
}

#honey-svg-asset > svg:hover {
  fill: #f37021;
}

.svgTextPath {
    color: #ffffff;
    font-size: 0.87rem;
    text-align: center;
    align-items: center !important;
    justify-content: center !important;
    height: 100%;
    display: flex;
}

.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
