.statusClass{
    .statusOverlay .statusOverlayIcon{
        visibility:hidden;
      }
    &:hover {
        cursor: pointer;
        .statusOverlay{
            width: 24px;
            height: 24px;
            border-radius: 50px;
            background: rgba(255, 255, 255, 1);

            float:"right";
            margin-left:"auto";
                                                                    
            .statusOverlayIcon {
            visibility:visible;
            }
          }
      }
      /* .statusOverlay svg {
        color:#D32F2F !important;
      } */
}

  
