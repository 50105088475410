.MuiFormHelperText-root{
    margin-top: -1px !important;
}
.dateFieldClass .MuiInputBase-input {
    height: 6px !important;
    width: 239px !important;
    
}
.dateFieldClass .MuiInputBase-root{
    background-color: #fff !important;
}

.dateFieldClass .MuiFormLabel-root{
    top: -7px !important;
}

.dateFieldClass .MuiFormLabel-filled {
    top: 0px !important;
}