.classMultilineHeader .MuiDataGrid-columnHeaderTitle {
  white-space: normal;
  word-wrap: break-word;
  line-height: normal;
  overflow: visible;
}

.denyComments {
  width: auto !important;
}

.pafGrid .MuiCheckbox-root.Mui-checked {
  color: #dc4f34 !important;
}

.visitDateField .MuiInputBase-root {
  height: 40px;
}

.fileList {
  padding: 0;
  padding-left: 8px;
  height: auto;
  background-color: #f6f6f6;
}

.timelineItemClass {
  min-height: auto !important;
}

.auditTimelineChip .MuiChip-label {
  padding-top: 4px !important;
  padding-right: 36px !important;
}
