.MuiMenu-paper{
    max-height: 278px !important;
    & ul li{
        font-size: 14px !important;
    }
}

.dropdownText{
    font-size: 14px !important;
}
.dropdownText div span{
    font-size: 14px !important;
}

 .spacingClass {
    height: 38px !important;
    margin-bottom: 13px !important;
} 

